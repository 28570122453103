import React, {useEffect} from 'react';
import cls from 'classname';
import acc from 'accounting';
import Icon from '../../../Icon';
import utils from '../../../Utils/utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Image from "../../../Components/Image";
import { columnTitles } from "../../../Components/titleSortFilters";
import Images from "../../../Images";
import {Dropdown} from "antd";
import IconWithText from "../../../Components/Text";
import TooltipColumnIcon from "./TooltipColumnIcon";
import {useDispatch, useSelector} from "react-redux";
import Creators from '../../AutoDS/reducer';

dayjs.extend(utc)

export const getColumns = (
  {
    sortOrder,
    handleSetRecordToCalc,
    handleSetRecordToPriceHistory,
    setVisible,
    setModal,
    handleOpenModalShopifyConnect,
    handleOpenModalAutoDSConnect,
    setCompetitor,
    handleExpandRow,
    toggleConnectStore,
    toggleConnectProduct,
    daysFromLaunch,
    t,
    isMobile=false,
    visibleDropdown,
    setVisibleDropdown
  }) => {

  const onSetRecordToCalc = (e, record) => {
    e.stopPropagation();
    handleSetRecordToCalc(record);
    setVisible(true);
    setModal('numbersBreakdown');
  }

  const onSetPriceHistory = (e, record) => {
    e.stopPropagation();
    handleSetRecordToPriceHistory(record);
    setVisible(true);
    setModal('priceHistory');
  }

  const onExpandRow = (e, record) => {
    e.stopPropagation();
    handleExpandRow(true, record);
  }

  return (
    [
      {
        title: 'Product',
        dataIndex: 'title',
        key: 'name',
        width: isMobile ? 'calc(100vw - 48px)' : 400,
        fixed: isMobile ? false : 'left',
        sorter: false,
        render: (text, record, index) => (
          <div className={'product-database-table-cell'}
               onClick={e => {
                 if (!e.target.classList.contains('product-url')) onExpandRow(e, record)
               }}
          >
              <div className={'product-database-table-image'}
              >
                 {record?.main_image ?
                <img src={utils.changeImageSize(record?.main_image, 124)}
                     alt=""
                     onError={utils.addDefaultSrc}
                     width="62" height="62"
                />
                :
                <Image src={Images.defaultImg} small={true}/>
              }
              </div>
              <div className="product-image-quantity">
                <Icon type={'database_product_images'}
                      role={'icon'}
                      color={'#707ba0'}
                      width={16}
                      height={16}
                />
                <span>{record?.images}</span>
              </div>
              <div className={'product-info-wrapper'}>
                <p className={'product-name'}>{record.title}
                  <span className="product-is-deleted">
                    {record?.is_deleted_from_st ?
                      <Icon role="button"
                            type={`unavailable_store_triangle`}
                            className={`unavailable-store-triangle`}
                            width={18}
                            height={18}
                            titleText={t('This store has been closed or paused.')}
                            tooltipProps={{
                              placement: 'right',
                              trigger: 'hover',
                              destroyTooltipOnHide: true,
                              overlayClassName: cls('details-tooltip', `unavailable-store-triangle-tooltip`),
                              getPopupContainer: () => document.getElementById('product-database-table-body'),
                            }}
                      />
                      :
                      null}
                  </span>
                </p>
                <p>
                  <a href={`https://${record?.store?.original_domain }/products/${record?.handle}`}
                     target={'_blank'}
                     rel="noopener noreferrer"
                  >
                    <span className={'product-url'}>
                      {
                        record?.store?.all_domains?.length > 0 && !isMobile ?
                        <>
                          {record?.store?.custom_domain}
                          <TooltipColumnIcon record={record} />
                        </>
                        :
                        record?.store?.custom_domain
                      }
                    </span>
                  </a>
                  <span className={'product-variants'}>{record.variants} {record.variants === 1 ? 'Variant': 'Variants'}</span>
                </p>
              </div>
          </div>
        ),
      },
      {
        title: ' ',
        fixed: isMobile ? false : 'left',
        dataIndex: 'quick_search',
        key: 'suppliers',
        width: 136,
        sorter: false,
        render: (text, record) => (
          <div className={'product-database-table-cell'}>
            <div className="product-suppliers">
              {record.quick_search.map(el => {
                if (el?.platform === 'aliexpress') return (
                  <a
                    key={el.platform}
                    href={el.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link with-img"
                  >
                    <Icon role="button"
                          type={`search_${el.platform}`}
                          className={`${el.platform}-icon`}
                          width={el.platform === 'alibaba' ? 24 : 16}
                          height={el.platform === 'alibaba' ? 24 : 16}
                          titleText={() => <>Search on <span style={{ textTransform: 'capitalize' }}>{el.platform}</span></>}
                          tooltipProps={{
                            placement: 'bottom',
                            trigger: 'hover',
                            destroyTooltipOnHide: true,
                            overlayClassName: cls('details-tooltip', `${el.platform}-tooltip`),
                            getPopupContainer: () => document.getElementById('product-database-table-body'),
                          }}
                    />
                  </a>
                )
              })}
              <div onClick={() => {
                handleOpenModalAutoDSConnect({
                  'source': 'shopify',
                  'product_id': `${record?.store?.id}_${record?.id}`
                })
              }}>
                <Icon role="button"
                      type={'auto_ds'}
                      isTooltip={true}
                      width={21}
                      height={20}
                      titleText={() => <>{t('Import Product to AutoDS')}</>}
                      tooltipProps={{
                        placement: 'bottom',
                        trigger: 'hover',
                        destroyTooltipOnHide: true,
                        overlayClassName: cls('details-tooltip', `aliexpress-tooltip`),
                        getPopupContainer: () => document.getElementById('product-database-table-body'),
                      }}
                />
              </div>
            </div>
          </div>
        ),
      },
      {
        title: '',
        key: 'price_history',
        width: 64,
        sorter: false,
        render: (text, record) => (
          <div className={'product-database-table-cell product-database-table-cell_clickable'}
               onClick={e => onSetPriceHistory(e, record)}
          >
            <div className="product-price-history">
              <Icon role={'icon'} type={'chart_icon'} />
            </div>
          </div>
        ),
      },
      {
        title: () => {
          return columnTitles({
            sortOrder,
            title: 'Price',
            key: 'usd_price',
          })
        },
        dataIndex: 'usd_price',
        key: 'usd_price',
        width: 216,
        sorter: true,
        render: (text, record) => (
          <div className={'product-database-table-cell product-database-table-cell_clickable'}
               onClick={e => onSetRecordToCalc(e, record)}
          >
            <div className="product-price">
              <p>${acc.format(record.usd_price, 2, ',', '.')} {record.usd_price_max &&
              <span>- ${acc.format(record.usd_price_max, 2, ',', '.')}</span>}</p>
              {record?.store?.currency !== 'USD' &&
              <p style={{marginTop: 8, fontSize: 12, lineHeight: '16px', color: '#707ba0'}}>
                {(record.original_price > 0 || record.original_price_max > 0) && acc.format(record.original_price, 2, ',', '.')} {record.original_price_max > 0 &&
              <span>- {acc.format(record.original_price_max, 2, ',', '.')}</span>} {(record.original_price > 0 || record.original_price_max > 0) && record.store.currency}
              </p>
              }
            </div>
          </div>
        ),
      },
      {
        title: () => {
          let result = (daysFromLaunch && daysFromLaunch < 30) ? `Sales ${daysFromLaunch} Days` : 'Monthly Sales'
          return columnTitles({
            sortOrder,
            title: result,
            key: 'monthly_sales',
          })
        },
        dataIndex: 'monthly_sales',
        key: 'monthly_sales',
        width: 216,
        sorter: true,
        render: (text, record) => (
          <div className={'product-database-table-cell product-database-table-cell_clickable'}
               onClick={e => onExpandRow(e, record)}
          >
            <div className="product-monthly-sales">
              {acc.format(record.monthly_sales, 0, ',', '.')}
            </div>
          </div>
        ),
      },
      {
        title: () => {
          let result = (daysFromLaunch && daysFromLaunch < 30) ? `Revenue ${daysFromLaunch} Days` : 'Monthly Revenue'
          return columnTitles({
            sortOrder,
            title: result,
            key: 'monthly_revenue',
          })
        },
        dataIndex: 'monthly_revenue',
        key: 'monthly_revenue',
        width: 216,
        sorter: true,
        render: (text, record) => (
          <div className={'product-database-table-cell product-database-table-cell_clickable'}
               onClick={e => onExpandRow(e, record)}
          >
            <div className="product-monthly-revenue">
              ${acc.format(record.monthly_revenue, 2, ',', '.')}
            </div>
          </div>
        ),
      },
      {
        title: () => {
          return columnTitles({
            sortOrder,
            title: 'Store Info',
            key: 'store_products_count',
          })
        },
        dataIndex: 'store',
        key: 'store_products_count',
        width: 216,
        sorter: true,
        render: (text, record) => (
          <div className={'product-database-table-cell product-database-table-cell_clickable'}
          >
            <div className="product-store-info">
              <p>{`${acc.format(record.store.products_count, 0, ',', '.')} ${t('Products')}`}</p>
              <p style={{ textTransform: 'capitalize' }}>{record.store.language}</p>
            </div>
          </div>
        ),
      },
      {
        title: () => {
          return columnTitles({
            sortOrder,
            title: 'Product creation date',
            key: 'created_at',
          })
        },
        dataIndex: 'created_at',
        key: 'created_at',
        width: 256,
        sorter: true,
        render: (text, record) => {
          return (
            <div className={'product-database-table-cell product-database-table-cell_clickable'}
                 onClick={e => onExpandRow(e, record)}
            >
              <div className="product-creation-date">
                {
                  record.created_at ?  dayjs(record.created_at, 'YYYY-MM-DDTHH:mm:ssZ').utc().format('MMM DD, YYYY') : 'No Data'
                }
              </div>
            </div>
          )
        },
      },
      {
        title: () => {
          return columnTitles({
            sortOrder,
            title: 'Store creation date',
            key: 'store_created_at',
          })
        },
        dataIndex: ['store']['created_at'],
        key: 'store_created_at',
        width: 194,
        sorter: true,
        render: (text, record) => (
          <div className={'product-database-table-cell product-database-table-cell_clickable'}
               onClick={e => onExpandRow(e, record)}
          >
            <div className="store-creation-date">
              {
                record.store.created_at ?  dayjs(record.store.created_at).format('MMM DD, YYYY') : t('No Data')
              }
            </div>
          </div>
        ),
      },
      {
        title: () => {
          return columnTitles({
            sortOrder,
            title: 'Category',
            key: 'category',
          })
        },
        dataIndex: 'category',
        key: 'category',
        width: 194,
        sorter: true,
        render: (text, record) => (
          <div className={'product-database-table-cell product-database-table-cell_clickable'}
               onClick={e => onExpandRow(e, record)}
          >
            <div className="product-category">
              {record.category}
            </div>
          </div>
        ),
      },
      {
        title: () => {
          return columnTitles({
            sortOrder,
            title: 'Product Type',
            key: 'product_type',
          })
        },
        dataIndex: 'product_type',
        key: 'product_type',
        width: 216,
        sorter: true,
        render: (text, record) => (
          <div className={'product-database-table-cell product-database-table-cell_clickable'}
               onClick={e => onExpandRow(e, record)}
          >
            <div className="product-type">
              {record.product_type}
            </div>
          </div>
        ),
      },
      {
        title: '',
        key: 'actions',
        width: isMobile ? 112 : 162,
        sorter: false,
        fixed: 'right',
        render: (text, record) => (
          <div className={'product-database-table-cell cell-actions'}>
            <div className="product-actions">
              <span>
              <DropdownRender record={record}
                              handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                              handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                              setCompetitor={setCompetitor}
                              t={t}
                              visibleDropdown={visibleDropdown}
                              setVisibleDropdown={setVisibleDropdown}
              />
            </span>
            <span>
              <DropdownRenderBtn record={record}
                                 toggleConnectProduct={toggleConnectProduct}
                                 toggleConnectStore={toggleConnectStore}
                                 t={t}
                                 cls={cls}
                                 isMobile={isMobile}
                                 visibleDropdown={visibleDropdown}
                                 setVisibleDropdown={setVisibleDropdown}
              />
            </span>
            </div>
          </div>
        ),
      },
    ]
  )
};

const DropdownRenderBtn = ({record, toggleConnectProduct, toggleConnectStore, t, cls, isMobile, visibleDropdown, setVisibleDropdown}) => {

  const handleInnerVisibleChange = (value) => {
    if (value) setVisibleDropdown({[record?.id]: {first: value}});
    else setVisibleDropdown({});
  };

  return (
    <Dropdown
      open={visibleDropdown[record?.id]?.first}
      onOpenChange={handleInnerVisibleChange}
      dropdownRender={() => (
        <ul className={'filter-preset-action-dropdown database-action-dropdown'} style={{width: 'unset'}}>
          <li className={cls("action-dropdown_item",{'tracked': record?.is_tracked})}
              onClick={() => {
                setVisibleDropdown({})
                toggleConnectProduct(record)
              }}
          >
            <IconWithText
              size={24}
              icon={() => <Icon type={'database_price'} role={'icon'} /> }
              text={record?.is_tracked ? t('Stop Tracking Product') : t('Start Tracking Product')}
            />
          </li>
          <li className={cls("action-dropdown_item",{'tracked': record?.store?.is_tracked})}
              onClick={() => {
                setVisibleDropdown({})
                toggleConnectStore(record)
              }}
          >
            <IconWithText
              size={24}
              icon={() => <Icon type={'tracking_store_database'} role={'icon'} /> }
              text={record?.store?.is_tracked ? t('Stop Tracking Store') : t('Start Tracking Store')}
            />
          </li>
        </ul>
      )}
      destroyPopupOnHide={true}
      placement="bottomRight"
      trigger={['click']}
      getPopupContainer={() => document.getElementById('product-database-table-body')}
      overlayClassName={'filter-preset-cell-menu-wrapper'}
    >
                <span className={cls('product-actions-options-btn', {
                  'product-actions-options-btn--tracked': record?.is_tracked && record.store.is_tracked
                })}>
                  {
                    isMobile ?
                      null
                      :
                    record?.is_tracked && record.store.is_tracked ?
                      t('Stop')
                      :
                      t('Track')
                  }
                  <Icon type="gps" role="icon" width={24} height={24} outline={!(record?.is_tracked || record.store.is_tracked)}/>
                </span>
    </Dropdown>
  )
}

const DropdownRender = ({record, handleOpenModalShopifyConnect, handleOpenModalAutoDSConnect, setCompetitor, t, visibleDropdown, setVisibleDropdown}) => {

    const dispatch = useDispatch();
    const autoStores = useSelector(store => store?.autoDS?.stores) || [];

    const handleInnerVisibleChange = (value) => {
        if (value) setVisibleDropdown({[record?.id]: {second: value}});
        else setVisibleDropdown({});
    };

    useEffect(() => {
        if (visibleDropdown?.[record?.id]?.second && !autoStores?.length) {
            dispatch(Creators.getAutoStoreRequest());
        }
    }, [visibleDropdown]);

  return (
    <Dropdown
      open={visibleDropdown?.[record?.id]?.second}
      onOpenChange={handleInnerVisibleChange}
      dropdownRender={() => (
        <ul className={'filter-preset-action-dropdown database-action-dropdown'} style={{width: 'unset'}}>
          <li className="action-dropdown_item"
              onClick={() => {
                setCompetitor(record.title)
                window.open('/competitor-research', '_blank')
              }}>
            <IconWithText
              size={24}
              icon={() => <Icon type={'search_icon'} role={'icon'} /> }
              text={t('Find Competitors')}
            />
          </li>
          <li className="action-dropdown_item no-hover"
              onClick={() => {
                setVisibleDropdown({});
                handleOpenModalShopifyConnect(`${record?.store?.id}_${record?.id}`)
              }}>
            <IconWithText
              size={24}
              icon={() => <Icon type={'shopify_btn_icon'} role={'icon'} isLogo={true} /> }
              text={t('Import Product to Shopify')}
            />
          </li>
          <li className="action-dropdown_item"
              onClick={() => {
                setVisibleDropdown({});
                handleOpenModalAutoDSConnect({
                  'source': 'shopify',
                  'product_id': `${record?.store?.id}_${record?.id}`
                })
              }}>
            <IconWithText
              icon={() => <Icon type={'auto_ds'} role={'icon'} /> }
              text={t('Import Product to AutoDS')}
            />
          </li>
        </ul>
      )}
      destroyPopupOnHide={true}
      trigger={['click']}
      placement="bottomRight"
      getPopupContainer={() => document.getElementById('product-database-table-body')}
      overlayClassName={'filter-preset-cell-menu-wrapper'}
    >
      <span className={'product-actions-options'}>
        <Icon type="three_dots" role="button" width={24} height={24}/>
      </span>
    </Dropdown>
  )
}
