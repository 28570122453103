import React, { useEffect, useState } from 'react';
import cls from "classname";
import utils from "../../../../Utils/utils";
import Image from "../../../../Components/Image";
import Images from "../../../../Images";
import TooltipColumnIcon from "../../../ProductDatabasePage/components/TooltipColumnIcon";
import {Divider} from "antd";
import acc from "accounting";
import dayjs from "dayjs";
import ButtonComponent from "../../../../Components/Button";
import Icon from "../../../../Icon";
import {useTranslation} from "react-i18next";
import Chart from "../../../../Components/Charts/Chart";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import './SalesTrackerTopPageTableItem.less';

const SalesTrackerTopPageTableItem = (
  {
    data,
    view,
    period,
    setModal,
    setVisible,
    setRecordToPriceHistory,
    isTrial,
    setRecordToDelete,
    setStoreProductsToShow,
    connectStore,
    connectProduct,
    isBasicPlan,
  }
  ) => {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const theme = useSelector(state => state.nav.theme);
  const isMobile = useSelector(state => state.nav.isMobile);

  const [localPeriod, setLocalPeriod] = useState(period);

  const totalInfo = view === 'stores' ?
    [
      {title: t('Total revenue'), value: `$${acc.formatNumber(data?.[`${localPeriod}_revenue`], '2', ',', '.')}`, withDivider: !isMobile },
      {title: t('Total sales'), value: acc.formatNumber(data?.[`${localPeriod}_sales`], '0', ',', '.'), withDivider: !isMobile },
      {title: t('Tracked by'), value: acc.formatNumber(data?.tracked_by, '0', ',', '.'), withDivider: !isMobile },
      {title: t('Products'), value: acc.formatNumber(data?.products_count, '0', ',', '.'),
        onClick: () => {
          setStoreProductsToShow(data?.internal_shop_id);
          setVisible(true);
          setModal('storeProducts');
        }}
    ]
    :
    [
      {title: t('Total revenue'), value: `$${acc.formatNumber(data?.[`${localPeriod}_revenue`], '2', ',', '.')}`, withDivider: !isMobile},
      {title: t('Total sales'), value: acc.formatNumber(data?.[`${localPeriod}_sales`], '0', ',', '.'), withDivider: !isMobile },
      {title: t('Tracked by'), value: acc.formatNumber(data?.tracked_by, '0', ',', '.'), withDivider: !isMobile},
      {title: t('Creation Date'), value: dayjs(data?.created_at).format('MMM DD, YYYY')}
    ]

  const mouseOver = e => {
    let target = e.target;
    const {scrollWidth, clientWidth} = target;
    if (scrollWidth > clientWidth) target.classList.add('overflowing')
  }

  const mouseLeave = e => {
    let target = e.target;
    target.classList.remove('overflowing')
  }

  useEffect(() => {
    setTimeout(() => setLocalPeriod(period), 500);
  }, [period]);

  return (
    <div className={cls('sales-tracker-top-table-item')}>
      <div className="sales-tracker-top-table-item-header">
        <div className="general-info">
          <div className="general-info-logo">
            {
              data?.is_locked ?
                <Icon type={'lock_top_stores'} role={'icon'} color={'#225aea'}/>
                :
                data?.[view === 'stores' ? 'logo' : 'main_image']
                  ? <img src={data?.[view === 'stores' ? 'logo' : 'main_image']}
                         alt=""
                         onError={utils.addDefaultSrc}
                         width="62" height="62"
                  />
                  : <Image src={Images.defaultImg} small={true}/>
            }
            {
              view === 'products' ?
                <div className="product-image-quantity">
                  <Icon type={'database_product_images'}
                        role={'icon'}
                        color={'#707ba0'}
                        width={16}
                        height={16}
                  />
                  <span>{data?.images}</span>
                </div>
                :
                null
            }
          </div>
          <div className="general-info-title-wrapper">
            <div className="general-info-title">
              {
                data?.is_locked ?
                  <span className="report-main-block_title">{t('Hidden')}</span>
                  :
                  view === 'products' ?
                    <a href={`https://${data?.store?.custom_domain}/products/${data?.handle}`}
                       target={'_blank'}
                       rel="noopener noreferrer"
                    >
                      <span className="report-main-block_title"
                            onMouseOver={mouseOver}
                            onMouseLeave={mouseLeave}
                            data-text={data?.title}
                            style={{display: 'block'}}
                      >
                        {data?.title || 'Some Title'}
                      </span>
                    </a>
                    :
                    <span className="report-main-block_title"
                          onMouseOver={mouseOver}
                          onMouseLeave={mouseLeave}
                          data-text={data?.title}
                          style={{display: 'block'}}
                    >
                      {data?.title || 'Some Title'}
                    </span>
              }
            </div>
            <div className="general-info-domain">
              {
                data?.is_locked ?
                  <span>{t('Hidden')}</span>
                  :
                  <>
                    {view === 'products' ?
                      <span className={'clickable'}
                            onClick={() => {
                              setRecordToPriceHistory({
                                store: {id: data?.store?.internal_shop_id},
                                id: data?.id,
                                main_image: data?.main_image,
                                title: data?.title,
                                usd_price: data?.usd_price,
                              });
                              setModal('priceHistory');
                              setVisible(true);
                            }}>
                        ${data?.usd_price}
                      </span>
                      :
                      null
                    }
                    {
                      view === 'products' ?
                        <Divider style={{margin: '0 8px'}} type={'vertical'}/>
                        :
                        null
                    }
                    <a className={'report-main-block_link chart-block_link'}
                       target={'_blank'}
                       href={view === 'stores' ? `https://${data?.custom_domain}` : `https://${data?.store?.custom_domain}`}
                       rel="noopener noreferrer">
                <span>{
                  view === 'stores' ?
                    data?.custom_domain
                    :
                    data?.store?.custom_domain
                }</span>
                      {isMobile ? null : <TooltipColumnIcon record={data} isST={view === 'stores'}/>}
                    </a>
                  </>
              }
            </div>
          </div>
        </div>

        <div className="total-info">
          {totalInfo.map(el => (
            <React.Fragment key={el?.title}>
              <div className="total-info-block">
                <div className="title">
                  {el?.title}
                </div>
                <div className={cls("value", {
                  'clickable': Boolean(el?.onClick) && !data?.is_locked
                })}
                     onClick={Boolean(el?.onClick) && !data?.is_locked ? el?.onClick : null}
                >
                  {data?.is_locked ? t('Hidden') : el?.title.includes('Tracked by') ? `${el?.value} ${t('Others')}` : el?.value}
                </div>
              </div>
              {el?.withDivider ? <Divider type={'vertical'} style={{margin: 'auto 24px', height: 32}} /> : null }
            </React.Fragment>
          ))}
        </div>

        <div className="actions">
          <ButtonComponent className={cls('sales-tracker-top-table-button sales-tracker-top-table-button-icon', {
            'dark': theme === 'dark'
          })}
                           onClick={() => window.open(data?.facebook_add_library, '_blank')}
                           text={''}
                           disabled={data?.is_locked}
          >
            <Icon role={'icon'} type={'search_facebook'} isDark={true}/>
          </ButtonComponent>
          <ButtonComponent className={cls('sales-tracker-top-table-button', {
            'sales-tracker-top-table-button-active': data?.is_tracked
          })}
                           onClick={() => {
                             if (data?.is_locked) navigate('/setting/plan')
                             else {
                               if (data?.is_tracked) {
                                 setVisible(true);
                                 if (isTrial) {
                                   setModal('canNotRemoveTracking');
                                 } else {
                                   setRecordToDelete({type: view.slice(0, -1), data: {...data}})
                                   setModal('deleteTracking');
                                 }
                               } else {
                                 view === 'stores' ?
                                   connectStore({
                                     "custom_domain": data?.custom_domain,
                                     "shopify_shop_id": data?.shopify_shop_id,
                                     "internal_shop_id": data?.internal_shop_id,
                                     "original_domain": data?.original_domain
                                   })
                                   :
                                   connectProduct({
                                     "custom_domain": data?.store?.custom_domain,
                                     "handle": data?.handle,
                                     "product_id": data?.id,
                                     "title": data?.title,
                                     "images": data?.images,
                                     "main_image": data?.main_image,
                                     "variants": data?.variants,
                                     "created_at": data?.created_at,
                                     "original_price": data?.original_price,
                                     "original_price_min": data?.original_price_min,
                                     "original_price_max": data?.original_price_max,
                                     "shopify_shop_id": data?.store?.shopify_shop_id,
                                     "internal_shop_id": data?.store?.internal_shop_id,
                                     "original_domain": data?.store?.original_domain
                                   })
                               }
                             }
                           }}
                           text={''}
          >
            <Icon role={'icon'} type={data?.is_locked ? 'unlock' : 'start_tracking'} />
            {t(data?.is_locked ? 'Upgrade Plan' : data?.is_tracked ? 'Stop tracking' : 'Start tracking')}
          </ButtonComponent>
        </div>
      </div>
      <div className="sales-tracker-top-table-item-chart">
        {
          !data?.is_locked ?
            <Chart height={isMobile ? null : '275px'}
                   data={data?.aggregations?.[localPeriod]?.revenue ? [...data.aggregations[localPeriod].revenue] : []}
                   type={'revenue'}
                   animation={false}
                   isMobile={isMobile}
            />
            :
            null
        }
        {
          data?.is_locked ?
            <div className={cls("hidden-chart", {
              "hidden-chart-dark": theme === 'dark'
            })}>
              <Chart height={'275px'}
                     data={data?.aggregations?.[localPeriod]?.revenue ? [...data.aggregations[localPeriod].revenue] : []}
                     type={'revenue'}
                     animation={false}
              />
              <div className="hidden-chart-text">
                <div className="hidden-chart-status">
                  {t('Access Limited')}
                </div>
                <div className="hidden-chart-title">
                  {
                    isBasicPlan ?
                      <>
                        <p>{t('Upgrade to Standard plan to unlock Top Stores, or go')}</p>
                        <p>{t('Premium plan to unlock Top Stores and Top Products.')}</p>
                      </>
                      :
                      t('Upgrade to Premium plan to unlock Top Products.')
                  }
                </div>
                <ButtonComponent className={'sales-tracker-top-table-button hidden-chart-button'}
                                 onClick={() => navigate('/setting/plan')}
                                 text={t('Upgrade Plan')}
                />
              </div>

            </div>
            :
            null
        }
      </div>
      {
        view === 'stores' && Object.keys(data?.best_selling_products?.[localPeriod] ? data?.best_selling_products?.[localPeriod] : {}).length ?
          <Divider style={{margin: '0 16px', width: 'calc(100% - 32px)', minWidth: 'unset'}} type={'horizontal'} />
          :
          null
      }
      {
        view === 'stores' && Object.keys(data?.best_selling_products?.[localPeriod] ? data?.best_selling_products?.[localPeriod] : {}).length ?
          <div className={'top-performing-products-wrapper'}>
            <h3>
              {t('Top Best Selling Products')}
            </h3>
            <div className={'top-performing-products'}>
              {
                (Object.values(data?.best_selling_products?.[localPeriod])?.length ? Object.values(data?.best_selling_products?.[localPeriod]) : []).map(item => (
                  <div key={item?.logo} className={'top-performing-product'}>
                    <div className={cls("image-wrapper", {
                      "image-wrapper-hidden": data?.is_locked
                    })}>
                      {item?.logo ?
                        <img src={utils.changeImageSize(item?.logo, 124)}
                             alt=""
                             onError={utils.addDefaultSrc}
                             width="62" height="62"
                        />
                        :
                        <Image src={Images.defaultImg} small={true}/>
                      }
                      <div className="product-image-quantity">
                        <Icon type={'database_product_images'}
                              role={'icon'}
                              color={'#707ba0'}
                              width={16}
                              height={16}
                        />
                        <span>{item?.images}</span>
                      </div>
                    </div>
                    <div className="title-wrapper">
                      {
                        !data?.is_locked ?
                          <a href={`https://${data?.original_domain}/products/${item?.handle}`}
                             target={'_blank'}
                             rel="noopener noreferrer"
                             onMouseOver={mouseOver}
                             onMouseLeave={mouseLeave}
                             data-text={item?.title}
                          >
                            {item?.title}
                          </a>
                          :
                          <span>Hidden</span>
                      }
                      <div className={'price-info'}
                           onClick={() => {
                             setRecordToPriceHistory({
                               store: {id: data?.internal_shop_id},
                               id: item?.id,
                               price: item?.price,
                               main_image: item?.logo,
                               title: item?.title,
                             });
                             setModal('priceHistory');
                             setVisible(true);
                           }}
                      >
                        <span>
                          {`$${acc.formatNumber(item?.price, 2, ',', '.')}`}
                        </span>
                        <Divider style={{margin: '0 8px'}} type={'vertical'} />
                        <span>
                          {`Revenue: $${acc.formatNumber(item?.revenue, 2, ',', '.')}`}
                        </span>
                      </div>

                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          :
          null
      }
    </div>
  );
};

export default SalesTrackerTopPageTableItem;
