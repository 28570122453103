import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Creators from '../reducer';
import { Switch } from 'antd';
import ButtonComponent from "../../../Components/Button";
import dayjs from "dayjs";
import './MainBlock.less';
import DropdownDateBlock from '../../ProductDatabasePage/components/DropdownDateBlock';

const dateOptions = [
  { id: 0, name: 'Last 7 days' },
  { id: 1, name: 'Last 30 days' },
  { id: 2, name: 'Last 90 days' },
  { id: 3, name: 'Last 6 months' },
  { id: 4, name: 'Last 12 months' },
];

export const MainBlock = (
  {
    data,
    downloadSalesReport,
    downloadRevenueReport,
    downloadProductReport,
    withMarginTop=false,
    showRawData = false,
    setShowRawData = null,
    rawDataAccess = false
  }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dropdownFilters = useSelector(store => store.salesTracker.filters) || {report_period: {min: new Date(dayjs()).toISOString(), max: new Date(dayjs().subtract(30, 'days')).toISOString()}};
  const checkedList = useSelector(store => store.salesTracker.checkedList) || [{id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30}];
  const [visibleModal, setVisibleModal] = useState(false);

  const fileLoading = useSelector(state => state?.salesTracker?.fileLoading);

  const setDropdownFilters = (prop) => {
    let result = prop();
    dispatch(Creators.setFilters(result))
  }

  const setMemoCheckedList = (data) => {
    dispatch(Creators.setCheckedList(data))
  }

  const handleDownload = () => {
    if (data.report === 'sales') downloadSalesReport({
      id: data.id,
      name: `${data.title} - ${data.reportName}`,
      date_range: data.dates,
      raw_data: rawDataAccess && showRawData
    })
    else if (data.report === 'product') downloadProductReport({
      id: data.id,
      name: `${data.title} - ${data.reportName}`,
      date_range: data.dates,
      raw_data: rawDataAccess && showRawData
    })
    else downloadRevenueReport({
        id: data.id,
        name: `${data.title} - ${data.reportName}`,
        date_range: data.dates,
        raw_data: rawDataAccess && showRawData
    })
  }

  return (
    <div className="report-main-block" style={{ marginTop: withMarginTop ? 24 : 0 }}>

      <span className={'main-block-calendar'}>
        <span>{t('Calendar')}</span> ({t('Store Timezone')}: UTC {data?.timeZone ? data?.timeZone : '+0:00'})
      </span>

      {
        rawDataAccess ?
          <div className="switch-option">
            <Switch checked={showRawData}
                    onChange={(state) => setShowRawData(state)}
            />
            <p onClick={() => setShowRawData((prev) => !prev)}
            >
              {
                t('Raw Data')
              }
            </p>
          </div>
          :
          null
      }

      <ButtonComponent type={'primary'}
                       loading={fileLoading}
                       className={'btn-primary white'}
                       onClick={handleDownload}
      >
        {t('Download Report')}
      </ButtonComponent>

      <DropdownDateBlock
        limit={data?.timeInfo}
        timeZone={data?.timeZone}
        label={null}
        customOverlayClassName={'dropdown-sales_tracker-wrapper'}
        customInnerOverlayClassName={'dropdown-sales_tracker-wrapper-inner'}
        id={'report_period'}
        iconType={'database_calendar'}
        data={dateOptions}
        dropdownFilters={dropdownFilters}
        setDropdownFilters={setDropdownFilters}
        memoCheckedList={checkedList}
        setMemoCheckedList={setMemoCheckedList}
        withName
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        position={'bottomRight'}
      />
    </div>
  );
};

export default MainBlock;
