import React, {useEffect, useState, useRef} from 'react';
import {connect, useDispatch} from "react-redux";
import Creators from '../reducer';
import cls from "classname";
import MainBlock from "./MainBlock";
import DropdownBarFilter from "../pages/components/DropdownBarFilter";
import DropdownCalendar from "../pages/components/DropdownCalendar";
import SearchWithTags from "../pages/components/SearchWithTags";
import utils from "../../../Utils/utils";
import {useTranslation} from "react-i18next";
import Images from "../../../Images";
import Icon from "../../../Icon";
import Image from "../../../Components/Image";
import acc from "accounting";
import dayjs from "dayjs";
import AutoDSCreators from "../../AutoDS/reducer";
import IconWithText from "../../../Components/Text";
import ButtonComponent from "../../../Components/Button";
import {Divider, Dropdown, Modal, Spin} from "antd";
import ProductCardsPageSkeleton from "./ProductCardsPageSkeleton";
import DeleteTrackingModal from "./DeleteTrackingModal";
import PriceHistory from '../../ProductDatabasePage/components/PriceHistory';
import './ProductCardsPage.less';
import { CanNotRemoveTrackingContent } from '../../ProductDatabasePage/components/CanNotRemoveTracking';

const ProductCardsPageItem = (
  {
    data,
    isTrial,
    id,
    active,
    connectProduct,
    setVisible,
    setModal,
    setRecordToDelete,
    setRecordToPriceHistory,
    handleOpenModalShopifyConnect,
    autoDS
  }) => {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const [visibleDropdown, setVisibleDropdown] = useState(false);

  const handleOpenModalAutoDSConnect = (importProductId) => {
    if (autoDS?.email) {
      if (autoDS?.stores?.length) dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'import', importProductId }));
      else dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'import_unavailable', importProductId: null }));
    } else dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'connect', importProductId }));
  }

  const mouseOver = e => {
    let target = e.target;
    const {scrollWidth, clientWidth} = target;
    setVisibleTooltip(true);
    if (scrollWidth > clientWidth) target.classList.add('overflowing')
  }

  const mouseLeave = e => {
    let target = e.target;
    setVisibleTooltip(false);
    target.classList.remove('overflowing')
  }

  return (
    <div className='product-cards-page-item' key={data?.id}>
      <div className="item-logo">
        {
          active === 'best' ?
            <div className="item-logo-rank">
              #{id + 1}
            </div>
            :
            null
        }
        <a  href={`https://${data?.store?.custom_domain}/products/${data?.handle}`}
            target={'_blank'}
            rel="noopener noreferrer"
        >
          {
            data?.main_image
              ? <img src={utils.changeImageSize(data?.main_image, 496)}
                     alt=""
                     onError={utils.addDefaultSrc}
              />
              : <Image src={Images.defaultImg} small={false}/>
          }
        </a>
      </div>

      <div className="item-content"
           style={{position: visibleTooltip ? 'relative' : 'static'}}
      >
        <div className="item-title"
             data-text={data?.title}
             onMouseOver={mouseOver}
             onMouseLeave={mouseLeave}
        >
          {data?.title}
        </div>
        <Divider style={{margin: '12px auto'}} type={'horizontal'} />
        <div className="item-stat">
          <span className="item-stat-key">
            {t('Price')}
          </span>
          <span className="item-stat-value clickable"
                onClick={() => {
                  setModal('priceHistory');
                  setVisible(true);
                  setRecordToPriceHistory(data);
                }}
          >
            ${acc.formatNumber(data?.usd_price, 2, ',', '.')}
            {
              data?.store?.currency !== 'USD'&&
              <span>({acc.formatNumber(data?.original_price, 2, ',', '.')} {data?.store?.currency})</span>
            }
          </span>
        </div>
        <div className="item-stat">
          <span className="item-stat-key">
            {t('Revenue')}
          </span>
          <span className="item-stat-value">
            ${acc.formatNumber(data?.revenue, 2, ',', '.')}
          </span>
        </div>
        <div className="item-stat">
          <span className="item-stat-key">
            {t('Sales')}
          </span>
          <span className="item-stat-value">
            {acc.formatNumber(data?.sales, 0, ',', '')}
          </span>
        </div>
        <div className="item-stat">
          <span className="item-stat-key">
            {t('Creation date')}
          </span>
          <span className="item-stat-value">
            {dayjs(data?.created_at).format('MMMM DD, YYYY')}
          </span>
        </div>
        <div className="item-btn-wrapper">
          <Dropdown
            open={visibleDropdown}
            onOpenChange={setVisibleDropdown}
            dropdownRender={() => (
              <ul className={'filter-preset-action-dropdown database-action-dropdown'} style={{width: 'unset'}}>
                <li className="action-dropdown_item no-hover"
                    onClick={() => {
                      setVisibleDropdown(false);
                      handleOpenModalShopifyConnect(`${data?.store?.internal_shop_id}_${data?.id}`)
                    }}>
                  <IconWithText
                    size={24}
                    icon={() => <Icon type={'shopify_btn_icon'} role={'icon'} isLogo={true} /> }
                    text={t('Import Product to Shopify')}
                  />
                </li>
                <li className="action-dropdown_item"
                    onClick={() => {
                      setVisibleDropdown(false);
                      handleOpenModalAutoDSConnect({
                        'source': 'shopify',
                        'product_id': `${data?.store?.id}_${data?.id}`
                      })
                    }}>
                  <IconWithText
                    icon={() => <Icon type={'auto_ds'} role={'icon'} /> }
                    text={t('Import Product to AutoDS')}
                  />
                </li>
              </ul>
            )}
            destroyPopupOnHide={true}
            trigger={['click']}
            placement="bottomRight"
            getPopupContainer={(trigger) => trigger.parentNode }
            overlayClassName={'filter-preset-cell-menu-wrapper'}
          >
            <ButtonComponent
              onClick={() => null}
              text={null}
              className={'item-btn import'}
            >
              <Icon type={'export_icon_database'} role={'icon'} />
              {t('Import')}
            </ButtonComponent>
          </Dropdown>
          <ButtonComponent onClick={() => {
            if (data?.is_tracked) {
              setVisible(true);
              if (isTrial) {
                setModal('canNotRemoveTracking');
              } else {
                setRecordToDelete({type: 'product', data: {...data}})
                setModal('deleteTracking');
              }
            } else {
              connectProduct({
                "custom_domain": data?.store?.custom_domain,
                "handle": data?.handle,
                "product_id": data?.id,
                "title": data?.title,
                "images": data?.images,
                "main_image": data?.main_image,
                "variants": data?.variants,
                "created_at": data?.created_at,
                "original_price": data?.original_price,
                "original_price_min": data?.original_price_min,
                "original_price_max": data?.original_price_max,
                "shopify_shop_id": data?.store?.shopify_shop_id,
                "internal_shop_id": data?.store?.internal_shop_id,
                "original_domain": data?.store?.original_domain
              })
            }
          }}
                           text={null}
                           className={cls('item-btn track', {
                             tracked: data?.is_tracked
                           })}
          >
            <Icon type={'gps'} role={'icon'} />
            {data?.is_tracked ? t('Stop') : t('Track')}
          </ButtonComponent>
        </div>
      </div>

    </div>
  )
}

const ProductCardsPage = (
  {
    minDate,
    maxDate,
    detailedInfoNew,
    timeZone,
    downloadSalesReport,
    downloadRevenueReport,
    id,
    loading,
    filtersLoading,
    countLoading,
    count,
    filters,
    cards,
    getCount,
    getCards,
    getFilters,
    isMobile,
    theme,
    deleteProduct,
    connectProduct,
    trackingLoading,
    userInfo,
    handleOpenModalShopifyConnect,
    resetFilters,
    autoDS
  }) => {

  const {t} = useTranslation();

  const tableRef = useRef(null);

  const [showRawData, setShowRawData] = useState(false);

  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  const initialValue = () => {
    let res = {};
    for (let filter in filters) {
      res[filter] = {
        min: filters[filter]?.min,
        max: filters[filter]?.max,
      }
    }
    return res;
  }

  const [active, setActive] = useState('best');
  const [value, setValue] = useState({
    price: {min: null, max: null},
    revenue: {min: null, max: null},
    sales: {min: null, max: null},
    created_at: {min: null, max: null},
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [sortOrder, setSortOrder] = useState('-sales');
  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToPriceHistory, setRecordToPriceHistory] = useState(null);
  const [firstLoading, setFirstLoading] = useState(true);

  const modalBlocksWidth = {
    deleteTracking: 512,
    canNotRemoveTracking: 450,
    priceHistory: 1040,
  };

  const modalBlocks = {
    deleteTracking: <DeleteTrackingModal setModal={setModal}
                                         record={recordToDelete}
                                         setVisible={setVisible}
                                         deleteStore={() => null}
                                         deleteProduct={deleteProduct}
                                         view={'Products'}
                                         isTopPage={true}
    />,
    canNotRemoveTracking: <CanNotRemoveTrackingContent setModal={setModal} isMobile={isMobile}/>,
    priceHistory: <PriceHistory product={recordToPriceHistory} isPD={false} />
  };

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if ((currentTopScroll >= maxTopScroll*0.95) && !loading && cards?.length < +count) {
      setPageNumber(prev => prev + 1);
      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  const applyFilters = (newSearch=false, withEmptySearch=false) => {
    let data = {
      "date_range": {
        "min": minDate,
        "max": maxDate
      },
      "price": {
        "min": value?.price?.min,
        "max": value?.price?.max
      },
      "sales": {
        "min": value?.sales?.min,
        "max": value?.sales?.max
      },
      "revenue": {
        "min": value?.revenue?.min,
        "max": value?.revenue?.max
      },
      "created_at": {
        "min": value?.created_at?.min,
        "max": value?.created_at?.max
      },
      "domain": withEmptySearch ? null : searchValue?.length ? searchValue : null,
      "page": newSearch ? 1 : pageNumber,
      "page_size": 20,
      "store_id": id,
      "ordering": sortOrder,
      "raw_data": userInfo?.raw_data_access && showRawData
    }

    getCards({...data})

  }

  const handleCount = (key={}) => {
    let data = {
      "date_range": {
        "min": minDate,
        "max": maxDate
      },
      "price": {
        "min": value?.price?.min,
        "max": value?.price?.max
      },
      "sales": {
        "min": value?.sales?.min,
        "max": value?.sales?.max
      },
      "revenue": {
        "min": value?.revenue?.min,
        "max": value?.revenue?.max
      },
      "created_at": {
        "min": value?.created_at?.min,
        "max": value?.created_at?.max
      },
      "domain": searchValue,
      "page": pageNumber,
      "page_size": 20,
      "store_id": id,
      "ordering": sortOrder,
      "raw_data": userInfo?.raw_data_access && showRawData
    }

    data[key.id].min = key?.value?.min
    data[key.id].max = key?.value?.max

    getCount({...data});
  }

  const searchSubmit = (withEmptySearch=false) => {
    applyFilters(true, withEmptySearch);
  }

  useEffect(() => {
    const tableContent = tableRef.current;
    if (tableContent && cards?.length < +count) {
      tableContent.addEventListener('scroll', onScrollHandlerFunc);
    }
    return () => {
      if (tableContent && !firstLoading) {
        tableContent.removeEventListener('scroll', onScrollHandlerFunc);
      }
    }
  }, [cards?.length, count, tableRef?.current])

  useEffect(() => {
    getFilters({
      date_range: {min: minDate, max: maxDate},
      store_id: id,
      raw_data: userInfo?.raw_data_access && showRawData
    });
    setSearchValue('');
    setPageNumber(1);
    setValue({
      price: {min: null, max: null},
      revenue: {min: null, max: null},
      sales: {min: null, max: null},
      created_at: {min: null, max: null},
    })
  }, [active, minDate, maxDate, showRawData]);

  useEffect(() => {
    if (JSON.stringify(value) === JSON.stringify(filters) && !filtersLoading && !loading) applyFilters()
    else setValue(initialValue());
  }, [JSON.stringify(filters), active, filtersLoading])

  useEffect(() => {
    if (firstLoading) setFirstLoading(false)
    else {
      if (!loading && Object.keys(value).length) applyFilters();
    }
  }, [pageNumber, JSON.stringify(value)])

  useEffect(() => {
    return () => {
      resetFilters();
    }
  }, [])

  if (filtersLoading) return (
    <ProductCardsPageSkeleton isMobile={isMobile} />
  )

  return (
      <div className='product-cards-page'>
        <div className="product-cards-page-categories">
          <div className={cls("product-cards-page-category", {
            active: active === 'best'
          })}
               onClick={() => {
                 setActive('best');
                 setSortOrder('-sales');
               }}
          >
            {t('Best Sellers')}
          </div>
          <div className={cls("product-cards-page-category", {
            active: active === 'newest'
          })}
               onClick={() => {
                 setActive('newest');
                 setSortOrder('-created_at');
               }}
          >
            {t('Newest Products')}
          </div>
          <div className={cls("product-cards-page-category", {
            active: active === 'all'
          })}
               onClick={() => {
                 setActive('all');
                 setSortOrder(null);
               }}
          >
            {t('All Products')}
          </div>
        </div>
        <MainBlock
          data={{
            title: detailedInfoNew?.title,
            link: detailedInfoNew?.custom_domain,
            logo: detailedInfoNew?.logo || detailedInfoNew?.favicon,
            report: 'sales',
            id: id,
            reportName: 'Store Report',
            dates: {min: minDate, max: maxDate},
            // timeInfo - 1st sale date
            timeInfo: detailedInfoNew?.first_sale_date,
            timeZone: timeZone,
          }}
          downloadSalesReport={downloadSalesReport}
          downloadRevenueReport={downloadRevenueReport}
          showRawData={showRawData}
          setShowRawData={setShowRawData}
          rawDataAccess={userInfo?.raw_data_access}
        />
        <div className="product-cards-page-filters-wrapper">
          <div className="product-cards-page-search">
            <SearchWithTags searchValue={searchValue}
                            search={search}
                            setSearch={setSearch}
                            setSearchValue={setSearchValue}
                            searchSubmit={searchSubmit}
                            disabled={false}
                            disabledBtn={loading || filtersLoading || countLoading}
            />
          </div>
          <div className="product-cards-page-dropdown-wrapper">
            <DropdownBarFilter key={'Price'}
                               title={'Price'}
                               tooltip={'The price products are sold for in USD.'}
                               position={'bottomRight'}
                               id={'price'}
                               isStore={false}
                               min={filters?.price?.min}
                               max={filters?.price?.max}
                               value={value}
                               setValue={setValue}
                               disabled={false}
                               isStandard={false}
                               countLoading={countLoading}
                               count={count}
                               handleCount={handleCount}
            />
            <DropdownBarFilter key={'Revenue'}
                               title={'Revenue'}
                               tooltip={'Product listing revenue generated based on the period you select either 7 Days or 90 Days.'}
                               position={'bottomRight'}
                               id={'revenue'}
                               isStore={false}
                               min={filters?.revenue?.min}
                               max={filters?.revenue?.max}
                               value={value}
                               setValue={setValue}
                               disabled={false}
                               isStandard={false}
                               countLoading={countLoading}
                               count={count}
                               handleCount={handleCount}
            />
            <DropdownBarFilter key={'Sales'}
                               title={'Sales'}
                               tooltip={'Number of sales a product listing generated based on the period you select either 7 Days, 30 Days or 90 Days.'}
                               position={'bottomRight'}
                               id={'sales'}
                               isStore={false}
                               min={filters?.sales?.min}
                               max={filters?.sales?.max}
                               value={value}
                               setValue={setValue}
                               disabled={false}
                               isStandard={false}
                               countLoading={countLoading}
                               count={count}
                               handleCount={handleCount}
            />

            <DropdownCalendar title={'Creation date'}
                              id={'created_at'}
                              value={value}
                              setValue={setValue}
                              disabled={false}
                              isStandard={false}
                              countLoading={countLoading}
                              count={count}
                              handleCount={handleCount}
            />
          </div>
        </div>
        <Spin spinning={loading || trackingLoading}>
        <div className="product-cards-page-items-wrapper" ref={tableRef}>
          {
            cards?.length || loading ?
              cards?.map((el, index) => <ProductCardsPageItem data={{...el}}
                                                              id={index}
                                                              active={active}
                                                              autoDS={autoDS}
                                                              connectProduct={connectProduct}
                                                              setVisible={setVisible}
                                                              setModal={setModal}
                                                              setRecordToDelete={setRecordToDelete}
                                                              setRecordToPriceHistory={setRecordToPriceHistory}
                                                              isTrial={isTrial}
                                                              handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
              />)
              :
              <div className="empty-state empty-state-product-database">
                <div className="empty-state-product-database-title">
                  {t('common.no_products')}
                </div>
                <div className="empty-state-product-database-subtitle">
                  {t('kindly_change_search')}
                </div>
                <div className="empty-state_icon">
                  <Icon width={96} height={96}
                        role="icon"
                        type="empty_warning"
                        fill={theme === 'dark' ? '#707ba0' : '#BDC4DB'}
                  />
                </div>
              </div>
          }
        </div>
        </Spin>
        <Modal
          className={cls("change-modal custom-modal", {
            'store-products-opened': modal === 'storeProducts',
            'collection-list-modal collection-modal': modal === 'canNotRemoveTracking'
          })}
          getContainer={() => document.getElementById('global-wrap')}
          {...(isMobile ? {transitionName: ''} : null)}
          open={visible}
          centered={!isMobile}
          closeIcon={
            <Icon role="icon" type="close_modal" color="#707BA0" opacity={1}/>
          }
          width={modalBlocksWidth[modal]}
          footer={null}
          closable="true"
          onCancel={() => setVisible(false)}
          destroyOnClose
          title={modal === 'canNotRemoveTracking' && t('Can not remove tracking')}
        >
          <Spin size="large" spinning={trackingLoading}>
            {modalBlocks[modal]}
          </Spin>
        </Modal>
      </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state?.salesTracker?.productCards?.loading,
  trackingLoading: state?.salesTracker?.productCards?.trackingLoading,
  filtersLoading: state?.salesTracker?.productCards?.filtersLoading,
  countLoading: state?.salesTracker?.productCards?.countLoading,
  count: state?.salesTracker?.productCards?.count,
  filters: state?.salesTracker?.productCards?.filters,
  cards: state?.salesTracker?.productCards?.cards?.results,
  isMobile: state?.nav?.isMobile,
  theme: state?.nav?.theme,
  userInfo: state?.auth?.userInfo,
  autoDS: state?.autoDS
});

const mapDispatchToProps = (dispatch) => ({
  getCount: (data) => dispatch(Creators.getCardsCountRequest(data)),
  getCards: (data) => dispatch(Creators.getCardsListRequest(data)),
  getFilters: (data) => dispatch(Creators.getCardsFiltersRequest(data)),
  deleteProduct: (data) => dispatch(Creators.deleteTrackingCardByIDRequest(data)),
  connectProduct: (data) => dispatch(Creators.createTrackingCardRequest(data)),
  resetFilters: () => dispatch(Creators.resetCardFilters()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardsPage);
