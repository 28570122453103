import React from 'react';
import {useTranslation} from "react-i18next";
import {Divider} from "antd";
import ButtonComponent from "../../../Components/Button";

const DeleteTrackingModalStoreProducts = (props) => {
  const {setModal, deleteStore, record, setVisible, view, deleteProduct, isTopPage=false} = props;
  const { t } = useTranslation();

  return (
    <div className={'load-filter-preset-wrapper delete-filter-preset-wrapper'}>
      <div className="load-filter-preset-title">
        {t('Are you sure?')}
      </div>
      <p className="load-filter-preset-subtitle">
        {t(record.type === 'store' ? 'Are you sure you want to stop tracking this store?' : 'Are you sure you want to stop tracking this product?')}
      </p>

      <Divider className={'delete-preset-divider'} />

      <div className="delete-filter-button-wrapper">
        <ButtonComponent className={'delete-preset-button-cancel'}
                         text={t('Cancel')}
                         onClick={() => {
                           setModal('');
                           setVisible(false);
                         }}
        />
        <ButtonComponent className={'delete-preset-button-apply delete-preset-button-cancel'}
                         text={t('Stop Tracking')}
                         onClick={() => {
                           //api call to delete
                           view === 'stores' ? deleteStore(isTopPage ?  {...record?.data, id: record?.data?.internal_shop_id} : record.data) : deleteProduct(isTopPage ? {...record?.data} : record.data)
                           setModal('');
                           setVisible(false);
                         }}
        />
      </div>
    </div>
  );
};

export default DeleteTrackingModalStoreProducts;