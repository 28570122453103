import React, { useCallback, useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import cls from 'classname';
import Creators from './reducer';
import Icon from '../../Icon';
import BannerBlock from './components/BannerBlock';
import FiltersBlock from './components/FiltersBlock';
import ShopifyStoreCreators from '../ShopifyStore/reducer';
import AutoDSCreators from '../AutoDS/reducer';
import DatabaseProducts from './components/DatabaseProducts';
import openNotification, { openNotificationWithIcon } from '../../Components/Notification/index';
import { DefaultMsgSaga } from '../../Components/Notification/notification-message';
import BannerLimited from '../../Components/Banners/BannerLimited';
import BannerSimple from '../../Components/Banners/BannerSimple';
import ReactPlayer from "react-player";
import {Modal} from "antd";
import './styles.less';

export const ProductDatabasePage = (
  {
    userInfo,
    isMobile,
    isAdmin,
    error,
    theme,
    activeSubscriptions,
    productDatabase,
    ...props
  }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //state to display skeleton on render
  const [firsLoading, setFirsLoading] = useState(true);
  //state check if filters were changed
  const [enabled, setEnabled] = useState(true);
  const [isChanged, setIsChanged] = useState(true);
  //state to toggle banner/table
  const [showTable, setShowTable] = useState(false);
  //show modal
  const [visible, setVisible] = useState(false);
  const [sortOrder, setSortOrder] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const currentScroll = useRef(null);

  //just deleted filter preset to call undo
  const deleteResult = useSelector(store => store['productDatabase'].deleteResult);
  const tableData = useSelector(store => store['productDatabase'].products.results) || [];
  const fetching = productDatabase.productsLoading;
  const chartLoading = productDatabase.chartLoading;

  const { loading, filtersLoading, attemptsLeft } = productDatabase;
  const isVisibleLimitRequest = userInfo?.subscriptions?.[0]?.plan?.plan?.name?.toLowerCase() === 'basic';
  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  //show notification after delete to undo
  useEffect(() => {
    if (deleteResult) {
      openNotificationWithIcon({
        deleteResult,
        className: 'notification preset-delete-notification',
        style: { minWidth: '716px' },
        message: (
          <DefaultMsgSaga
            text={
              <span className="preset-notification-block">
                Preset successfully removed from your list.
              </span>
            }
            title={t('Preset was successfully removed')}
            iconOutline={true}
            icon="trash_paper"
            preset={deleteResult.name}
            withTranslate={true}
          />
        ),
      });
      setTimeout(() => dispatch(Creators.resetDeleteResult()), 1);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [dispatch, deleteResult]);

  useEffect(() => {
    document.title = `Product Database - Dropship`;
    if (firsLoading) setTimeout(() => setFirsLoading(false), 1);
    props.getFilters();
    props.getPresets('');
    props.getShopifyStore();
    props.getAutoStore();
    dispatch(Creators.resetCreateError());
    dispatch(Creators.resetDeleteResult());
    props.resetProducts();

    return () => {
      props.setCompetitor(false);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if(isMobile) setVisible(false)
  }, [isMobile])

  useEffect(() => {
    if (showTable && !isChanged) setEnabled(false);
  }, [showTable, isChanged]);

  useEffect(() => {
    if (attemptsLeft === 0 && !isMobile && (!firsLoading && !loading && !filtersLoading)) {
      openNotification({
        message: t('You have reached your daily search limit, upgrade to unlock unlimited searches'),
        style: { minWidth: '716px' },
        type: 'error',
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [attemptsLeft]);

  const handleChangePageSize = useCallback(value =>
      setPageSize(value),
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
    []);
  const handleChangePageNumber = useCallback(value =>
      setPageNumber(value),
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
    []);
  const handleSetCompetitor = useCallback(data =>
      props.setCompetitor(data),
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
    [])
  const handleGetProductChart = useCallback(data =>
      props.getProductChart(data),
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
    [])

  const handleSort = useCallback(({ order, columnKey }) => {
    setPageNumber(1);
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, []);

  const headerSkeleton = () => (
    <div className="sales-tracker-top-page-header">
      <div className="title-wrapper">
        <span className={'link skeleton'} style={{width: 40, height: 40}}/>
        <div className="sales-tracker-top-page-subheader">
          <span className={'link skeleton'} style={{width: 241, height: 16}}/>
          <span className={'link skeleton'} style={{width: 337, height: 16, marginTop: 4}}/>
        </div>
      </div>
      <div className={'sales-tracker-top-page-limits-wrapper'}>
        <span className={'link skeleton'} style={{width: 137, height: 40}}/>
        <span className={'link skeleton'} style={{width: 168, height: 40}}/>
      </div>
    </div>
  )

  const header = () => (
    <div className="sales-tracker-top-page-header">
      <div className="title-wrapper">
        <div className="title-wrapper-icon">
          <Icon type={`product_database_dashboard`} role={'icon'} width={20} height={20} />
        </div>
        <div className="sales-tracker-top-page-subheader">
          <h3 className="sales-tracker-top-page-title">
            {t(`Product Database`)}
          </h3>
          <h4 className="sales-tracker-top-page-subtitle">
            {t(`Search millions of products and see their revenue`)}
          </h4>
        </div>
      </div>
      <div className={'sales-tracker-top-page-limits-wrapper'}>
        <div className={'sales-tracker-top-page-watch-tutorial'}
             onClick={() => window.Intercom('startTour',433081)}
             style={{width: 138}}
        >
          <Icon type={'take_tour'} role={'icon'}/>
          <span>{t('Take tour')}</span>
        </div>

        <div className={'sales-tracker-top-page-watch-tutorial'}
             onClick={() => setVisible(true)}
        >
          <span className="watch-tutorial-icon">
            <Icon type={'watch_tutorial'} role={'icon'} width={24} height={24}/>
            <span>{t('Watch tutorial')}</span>
          </span>
        </div>
      </div>
    </div>
  )

  return (
    //custom scrollbars
    <div className={cls("product-database-page", {
      'product-database-page-no_scroll': visible
    })}>
      {
        isTrial || isVisibleLimitRequest
          ? (
            <>
              {
                firsLoading || filtersLoading ?
                  headerSkeleton()
                  :
                  header()
              }
            <BannerLimited
              skeleton={firsLoading || filtersLoading}
              className={cls({ 'limited-banner_error': attemptsLeft === 0 })}
              text={`${t('Daily Searches Remaining')}: ${attemptsLeft}`}
              btnText={t('Upgrade For Unlimited')}
              onClick={() => navigate('/setting/plan')}
              isTrial={isTrial}
            />
            </>
              )
          : null
      }

      {
          <>
            {
              !isAdmin && !(isTrial || isVisibleLimitRequest) ?
                firsLoading || filtersLoading ?
                  headerSkeleton()
                  :
                  header()
                :
                null
            }
            <FiltersBlock
              skeleton={firsLoading || filtersLoading}
              isMobile={isMobile}
              theme={theme}
              productsLoading={fetching}
              fetching={firsLoading || loading || filtersLoading}
              savePreset={props.savePreset}
              deletePreset={props.deletePreset}
              updatePreset={props.updatePreset}
              getProducts={props.getProducts}
              setShowTable={setShowTable}
              showTable={showTable}
              pageSize={pageSize}
              setPageSize={handleChangePageSize}
              pageNumber={pageNumber}
              setPageNumber={handleChangePageNumber}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              isChanged={(isTrial || isVisibleLimitRequest) ? (attemptsLeft >= 0 && isChanged) : isChanged}
              disabledSearch={((isTrial || isVisibleLimitRequest) && (attemptsLeft === 0)) || !Boolean(userInfo?.subscriptions?.[0])}
              setIsChanged={setIsChanged}
              setEnabled={setEnabled}
              currentScroll={currentScroll}
              getPresets={props.getPresets}
            />

            {showTable ?
              //condition toggle table/empty table
              !tableData.length && !fetching ?
                <div className={cls('product-database-table-wrapper', {
                  'product-database-table-wrapper-empty': !tableData?.length,
                })}>
                  <div className="empty-state empty-state-product-database">
                    <div className="empty-state-product-database-title">
                      {t('No Products Found')}
                    </div>
                    <div className="empty-state-product-database-subtitle">
                      {t('Kindly change your search query and try again.')}
                    </div>
                    <div className="empty-state_icon">
                      <Icon width={96} height={96}
                            role="icon"
                            type="empty_warning"
                            fill={theme === 'dark' ? '#707ba0' : '#BDC4DB'}
                      />
                    </div>
                  </div>
                </div>
                :
                <DatabaseProducts fetching={fetching}
                                  loading={loading}
                                  theme={theme}
                                  onSort={handleSort}
                                  pageSize={pageSize}
                                  setPageSize={handleChangePageSize}
                                  pageNumber={pageNumber}
                                  setPageNumber={handleChangePageNumber}
                                  data={tableData}
                                  sortOrder={sortOrder}
                                  isChanged={(isTrial || isVisibleLimitRequest) ? false : isChanged}
                                  enabled={enabled}
                                  setCompetitor={handleSetCompetitor}
                                  getChart={handleGetProductChart}
                                  chartLoading={chartLoading}
                                  currentScroll={currentScroll}
                                  daysFromLaunch={productDatabase?.products?.['days_from_launch']}
                                  isTrial={isTrial}
                                  isMobile={isMobile}
                />
              :
              null
            }
          </>
      }

      {
        !showTable && <>
          {
              <BannerSimple
                skeleton={firsLoading || filtersLoading}
                text={t('Sales data are estimates and may contain error margins.')}
                description={t('Error margins increase for products with lower sales volume and decrease for products with higher sales volume.')}
          />}
          <BannerBlock
            skeleton={firsLoading || filtersLoading}
            isMobile={isMobile}
            theme={theme}
            setVisible={setVisible}
          />
        </>
      }

      <Modal
        className="change-modal custom-modal video-modal"
        getContainer={() => document.getElementById('global-wrap')}
        {...(isMobile ? {transitionName: ''} : null)}
        open={visible}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#225aea" opacity={1}/>
        }
        footer={null}
        closable="true"
        onCancel={() => setVisible(false)}
        destroyOnClose
      >
        <ReactPlayer controls={true}
                     width={'100%'}
                     height={'100%'}
                     playing={true}
                     url={'https://www.youtube.com/watch?v=OftbjjbL0tc'}
        />
      </Modal>
    </div>
  );
};

ProductDatabasePage.defaultProps = {};

ProductDatabasePage.propTypes = {
  isAdmin: PropTypes.bool,
  isMobile: PropTypes.bool,
  theme: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  productDatabase: state.productDatabase,
});

const mapDispatchToProps = (dispatch) => ({
  getFilters: () => dispatch(Creators.getProductDatabaseFiltersRequest()),
  getPresets: (data) => dispatch(Creators.getProductDatabasePresetsRequest(data)),
  savePreset: (data) => dispatch(Creators.createProductDatabasePresetRequest(data)),
  deletePreset: (id) => dispatch(Creators.deleteProductDatabasePresetRequest(id)),
  updatePreset: (data) => dispatch(Creators.updateProductDatabasePresetRequest(data)),
  getProducts: (data) => dispatch(Creators.getProductsDatabaseRequest(data)),
  resetProducts: () => dispatch(Creators.resetProductsDatabase()),
  getProductChart: (data) => dispatch(Creators.getProductChartRequest(data)),
  setCompetitor: (data) => dispatch(Creators.setCompetitorResearch(data)),
  getShopifyStore: () => dispatch(ShopifyStoreCreators.getShopifyStoresRequest()),
  getAutoStore: () => dispatch(AutoDSCreators.getAutoStoreRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDatabasePage);
