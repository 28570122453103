import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Divider, Dropdown, Spin } from 'antd';
import Creators from './reducer';
import {openNotificationWithIcon} from '../../Components/Notification';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import Modal from '../../Components/Modal';
import ButtonComponent from '../../Components/Button';
import './styles.less';
import { DefaultMsg } from '../../Components/Notification/notification-message';

const text = 'You can import the product to your existing Shopify store or connect a new store to your Dropship account.';

const ModalShopifyImport = (
  {
    isMobile,
    theme,
    userInfo,
    shopifyStore,
    closeModalShopify,
    ...props
  }) => {

  const {
    loading,
    isVisibleModal,
    importErrors,
    importResult,
    importResultData,
    results,
    importProductId,
    isTrial,
  } = shopifyStore;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const checkOauth = shopifyStore?.checkOauth;
  const [visible, setVisible] = useState(false);
  const [visibleNewStoreInput, setVisibleNewStoreInput] = useState(false);
  const [selectStore, setSelectStore] = useState(null);
  const [storeName, setStoreName] = useState(null);

  const count = results.length || 0;
  const REDIRECT_URL_TO_OUR_SITE = document?.location?.origin + '/connect-shopify/verify'; //href

  useEffect(() => {
    if (count && !selectStore && isVisibleModal === 'import') {
      setSelectStore(results?.[0]);
    }
    dispatch(Creators.resetOauth());
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [count, isVisibleModal]);

  useEffect(() => {
    if (checkOauth && isVisibleModal === 'import' && storeName !== null) {
      window.open(`https://${storeName}.myshopify.com/admin/oauth/authorize?client_id=${process.env.REACT_APP_SHOPIFY_STORE_KEY}&scope=write_products&redirect_uri=${REDIRECT_URL_TO_OUR_SITE}&state=${userInfo?.id}&grant_options[]=value`, '_self');
    } else if (!checkOauth && typeof checkOauth !== 'object' && isVisibleModal === 'import'){
    openNotificationWithIcon({
      key: `open${Date.now()}`,
      style: { width: '400px' },
      className: 'notification notification_delete',
      message: (
        <DefaultMsg text={'This store is already connected.'}
                    icon="notification_warning"
                    title={'Something went wrong'}
        />
      ),
    });
  }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [checkOauth, isVisibleModal]);

  useEffect(() => {
    if (importResult || importErrors || importResultData) {
      if (importResult && importResultData) {
        openNotificationWithIcon({
          style: { width: '450px' },
          className: 'notification notification--save',
          message: (
            <DefaultMsg        text={
              <a 
              href={importResultData?.message?.link?.url || '#'} 
              rel="noopener noreferrer" 
              target="_blank" 
            >
              {importResultData?.message?.link?.text || 'Learn more'}
            </a>
            }
                        icon="create_preset"
                        title={importResultData.message?.detail}
            />
          ),
        });
        handleClose();
      }
      if(importErrors){
        openNotificationWithIcon({
          key: `open${Date.now()}`,
          style: { width: '400px' },
          className: 'notification notification_delete',
          message: (
            <DefaultMsg text={importErrors?.detail || importErrors}
                        icon="notification_warning"
                        title={'Something went wrong'}
            />
          ),
        });
      }
      props.importProductShopifyStoreReset();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [importResult, importErrors, isTrial]);

  const handleClose = () => {
    if (selectStore) setSelectStore(null);
    if (isVisibleModal === 'import') closeModalShopify();
  };

  const handleVisibleChange = (value) => {
    setVisible(value);
    if (!value && visibleNewStoreInput) setVisibleNewStoreInput(false);
    if (!value && storeName) setStoreName(null);
  };

  const handleSelectStore = (store) => {
    if (selectStore?.id !== store?.id) setSelectStore(store);
    if (visibleNewStoreInput) setVisibleNewStoreInput(false);
    if (storeName) setStoreName(null);
    setVisible(false);
  };

  const handleVisibleNewStoreInput = () => {
    window.open('https://apps.shopify.com/dropship-10?show_store_picker=1', '_self');
  };

  function handleChange(e) {
    const { value } = e.target;
    setStoreName(value);
  }

  const handleImport = () => {
    if (loading) return;
    if (selectStore?.id && importProductId) {
      props.importProductShopifyStore({ shop_id: selectStore?.id, product_id: importProductId });
    }
  };

  return (
    <Modal handleOk={null}
           handleClose={handleClose}
           title={t('Import product to Shopify')}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal shopify-import"
           open={isVisibleModal === 'import'}
           destroyOnClose
    >
      <Spin spinning={loading}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {t(text)}
          </p>
          <div className={'import-dropdown-wrap'}>
            <Dropdown overlayClassName={'dropdown-store-wrapper'}
                      destroyPopupOnHide={true}
                      getPopupContainer={trigger => isMobile ? document.getElementById('global-wrap') : trigger.parentNode}
                      onOpenChange={handleVisibleChange}
                      dropdownRender={() => (
                          <ul className="user_store-list" style={{ maxHeight: 168 }}>
                            {
                              results?.map(({ id, name, domain, ...other }) => (
                                <li key={id}
                                    className="user_store-item user_store-item_clickable"
                                    onClick={() => handleSelectStore({ id, name, domain })}
                                >
                                  <span className="user_store-name">{name}</span>
                                  <span className="user_store-link">{domain}</span>
                                  {
                                    id === selectStore?.id ?
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                           fill="none">
                                        <path d="M1.83398 10.0625L6.00065 13.5L14.1673 2.5" stroke="#225AEA"
                                              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                      </svg>
                                      :
                                      null
                                  }
                                </li>
                              ))
                            }
                          </ul>
                      )}
                      trigger={['click']}
                      open={visible}
            >
              <div className="dropdown-store">
                <span className="dropdown-store_name">
                  {selectStore?.name}
                </span>
                <span className="dropdown-store_domain">
                  {selectStore?.domain}
                </span>
                <ArrowSelectDown />
              </div>
            </Dropdown>
          </div>
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '24px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-create"
                           onClick={handleVisibleNewStoreInput}
                           text={t('Connect new store')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           onClick={handleClose}
                           text={t('Cancel')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save"
                           disabled={loading}
                           onClick={handleImport}
                           text={t('Import')}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

ModalShopifyImport.defaultProps = {
  shopifyStore: {
    loading: false,
    results: [],
    importProductId: null,
  },
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  userInfo: state.auth.userInfo,
  shopifyStore: state.shopifyStore,
});

const mapDispatchToProps = (dispatch) => ({
  importProductShopifyStore: (data) => dispatch(Creators.importProductShopifyStoreRequest(data)),
  importProductShopifyStoreReset: () => dispatch(Creators.importProductShopifyStoreReset()),
  closeModalShopify: () => dispatch(Creators.changeVisibleModalShopify({
    isVisibleModal: null,
    initialUrl: null,
    importProductId: null,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalShopifyImport);
